import qs from "qs";
import { useLanguageStore } from "~/store/languageStore";

export const getCategoriesQuery = () => {
  return qs.stringify({
    locale: useLanguageStore().language.name,
    fields: ["position", "name", "slug", "metaTitle", "metaDescription"],
    sort: ["position:asc"],
  });
};

export const getTagsQuery = () => {
  return qs.stringify({
    locale: useLanguageStore().language.name,
    fields: ["name", "slug", "group", "metaTitle", "metaDescription"],
  });
};

export const getPostsQuery = (pagination, filters = undefined) => {
  return qs.stringify({
    locale: useLanguageStore().language.name,
    fields: ["title", "slug", "views", "createdAt"],
    pagination,
    populate: {
      author: {
        populate: ["photo"],
      },
      tags: true,
      cover: true,
    },
    sort: "createdAt:desc",
    filters,
  });
};

export const getPostQuery = () => {
  return qs.stringify({
    locale: useLanguageStore().language.name,
    populate: {
      author: {
        populate: ["photo"],
      },
      tags: true,
      cover: true,
    },
  });
};
